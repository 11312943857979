exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-ai-in-astrology-js": () => import("./../../../src/pages/faq/ai-in-astrology.js" /* webpackChunkName: "component---src-pages-faq-ai-in-astrology-js" */),
  "component---src-pages-faq-ai-vs-human-astrologers-js": () => import("./../../../src/pages/faq/ai-vs-human-astrologers.js" /* webpackChunkName: "component---src-pages-faq-ai-vs-human-astrologers-js" */),
  "component---src-pages-faq-ayanamsa-calculation-js": () => import("./../../../src/pages/faq/ayanamsa-calculation.js" /* webpackChunkName: "component---src-pages-faq-ayanamsa-calculation-js" */),
  "component---src-pages-faq-dashas-explained-js": () => import("./../../../src/pages/faq/dashas-explained.js" /* webpackChunkName: "component---src-pages-faq-dashas-explained-js" */),
  "component---src-pages-faq-how-lagna-360-works-js": () => import("./../../../src/pages/faq/how-lagna360-works.js" /* webpackChunkName: "component---src-pages-faq-how-lagna-360-works-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-lagna-360-astrology-ai-vs-gpt-js": () => import("./../../../src/pages/faq/lagna360-astrology-ai-vs-gpt.js" /* webpackChunkName: "component---src-pages-faq-lagna-360-astrology-ai-vs-gpt-js" */),
  "component---src-pages-faq-upagrahas-aprakasha-grahas-js": () => import("./../../../src/pages/faq/upagrahas-aprakasha-grahas.js" /* webpackChunkName: "component---src-pages-faq-upagrahas-aprakasha-grahas-js" */),
  "component---src-pages-faq-vedic-astrology-vs-western-js": () => import("./../../../src/pages/faq/vedic-astrology-vs-western.js" /* webpackChunkName: "component---src-pages-faq-vedic-astrology-vs-western-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-planets-placed-in-house-js": () => import("./../../../src/pages/learn/planets/placed-in-house.js" /* webpackChunkName: "component---src-pages-learn-planets-placed-in-house-js" */),
  "component---src-pages-learn-planets-placed-in-nakshatra-js": () => import("./../../../src/pages/learn/planets/placed-in-nakshatra.js" /* webpackChunkName: "component---src-pages-learn-planets-placed-in-nakshatra-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-components-filter-js": () => import("./../../../src/pages/resources/components/Filter.js" /* webpackChunkName: "component---src-pages-resources-components-filter-js" */),
  "component---src-pages-resources-components-google-js": () => import("./../../../src/pages/resources/components/Google.js" /* webpackChunkName: "component---src-pages-resources-components-google-js" */),
  "component---src-pages-resources-components-table-js": () => import("./../../../src/pages/resources/components/Table.js" /* webpackChunkName: "component---src-pages-resources-components-table-js" */),
  "component---src-pages-resources-mundane-js": () => import("./../../../src/pages/resources/mundane.js" /* webpackChunkName: "component---src-pages-resources-mundane-js" */),
  "component---src-pages-resources-natal-js": () => import("./../../../src/pages/resources/natal.js" /* webpackChunkName: "component---src-pages-resources-natal-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-mundane-template-js": () => import("./../../../src/templates/mundane-template.js" /* webpackChunkName: "component---src-templates-mundane-template-js" */),
  "component---src-templates-nakshatra-page-template-js": () => import("./../../../src/templates/nakshatra-page-template.js" /* webpackChunkName: "component---src-templates-nakshatra-page-template-js" */),
  "component---src-templates-natal-template-js": () => import("./../../../src/templates/natal-template.js" /* webpackChunkName: "component---src-templates-natal-template-js" */),
  "component---src-templates-placed-in-house-results-mundane-template-js": () => import("./../../../src/templates/placed-in-house-results-mundane-template.js" /* webpackChunkName: "component---src-templates-placed-in-house-results-mundane-template-js" */),
  "component---src-templates-placed-in-house-results-natal-template-js": () => import("./../../../src/templates/placed-in-house-results-natal-template.js" /* webpackChunkName: "component---src-templates-placed-in-house-results-natal-template-js" */),
  "component---src-templates-placed-in-nakshatra-results-mundane-template-js": () => import("./../../../src/templates/placed-in-nakshatra-results-mundane-template.js" /* webpackChunkName: "component---src-templates-placed-in-nakshatra-results-mundane-template-js" */),
  "component---src-templates-placed-in-nakshatra-results-natal-template-js": () => import("./../../../src/templates/placed-in-nakshatra-results-natal-template.js" /* webpackChunkName: "component---src-templates-placed-in-nakshatra-results-natal-template-js" */),
  "component---src-templates-planet-page-template-js": () => import("./../../../src/templates/planet-page-template.js" /* webpackChunkName: "component---src-templates-planet-page-template-js" */)
}

